import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'subscribe-event',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        name: '',
        phone: '',
        promocode: ''
      },
      validationErrors: {},
    }
  },
  components: {
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      phone: {
        required,
        minLength: minLength(9)
      },
      promocode: {
        maxLength: maxLength(60)
      },
    }
  },
  computed: {
    ...mapGetters({
      sendHelpFormLoading: 'contactForm/sendHelpFormLoading',
      sendSubscribeFormLoading: 'contactForm/sendSubscribeFormLoading',
      recordPopupContent: 'event/recordEventPopup',
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationLengthPhone'));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    promocodeErrors() {
      let error = [];
      if (!this.$v.payload.promocode.$dirty) {
        return error;
      }
      if (!this.$v.payload.promocode.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (this.validationErrors.promocode) {
        this.validationErrors.promocode.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendSubscribeForm: `contactForm/SEND_SUBSCRIBE_FORM`,
    }),
    ...mapMutations({
      changeShowSubscribePopup: `event/CHANGE_SHOW_SUBSCRIBE_EVENT_POPUP`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    getConfigItem(key) {
      for (let i in this.config) {
        if (this.config[i].key === key) {
          return this.config[i];
        }
      }
    },
    resetForm(){
      this.$v.$reset();
      this.payload.name = '';
      this.payload.phone = '';
      this.payload.email = '';
      this.payload.promocode = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.promocode = this.payload.promocode;
        obj.phone = this.payload.phone.replace(/[\s.,%()-]/g, '');
        if(this.recordPopupContent.title){
          obj.course = this.recordPopupContent.title;
        }
        if(this.recordPopupContent.price){
          obj.price = parseInt(this.recordPopupContent.price);
        }
        console.log(obj);
        this.sendSubscribeForm(obj)
          .then(() => {

            this.changeShowSubscribePopup(false);

            this.$nextTick(()=>{
              this.changeContentPopup({
                text: this.$t('popup.subscribeSuccessInfo'),
              });
            })
            setTimeout(()=> {
              this.changeShowContentPopup(true);
            },200)
          }).catch(error => {
          let formattedMessage = null;

          if (error && error.response) {
            if (error.response.data.message) {
              formattedMessage = error.response.data.message;
            }

            if (error.response.data.error) {
              formattedMessage = error.response.data.error.message;
            }

            if (error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach(errorKey => {
                error.response.data.errors[errorKey].forEach((line) => {
                  formattedMessage += `\r\n${line}`;
                });
              });
            }
          }

          formattedMessage && this.$toasted.error(formattedMessage);
        });
      }
    },
  }
}
